/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-16",
    versionChannel: "nightly",
    buildDate: "2024-11-16T00:06:34.748Z",
    commitHash: "99561d98c5064be69f29b95cd47ec92d5a5de757",
};
